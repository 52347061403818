<template>
  <div>
    <section class="flex w-full pt-32 px-2 pb-5">
      <div class="flex justify-center w-full">
        <div class="flex-col justify-center my-4">
          <div
            class="text-secondary-dark text-center text-3xl font-medium my-2"
          >
            Work With Us
          </div>
          <div class="rounded m-auto delimiter-line"></div>
          <div class="flex flex-col justify-center text-center m-5 sm:flex-row">
            <span class="text-sm text-grey-smooth mx-3">IMPROVE SKILLS</span>
            <span class="text-sm text-grey-smooth mx-3">MAKE FRIENDS</span>
            <span class="text-sm text-grey-smooth mx-3">LEARN NEW THINGS</span>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full career-offers">
      <div
        class="flex flex-row flex-wrap justify-center card-job px-0 m-auto -mt-16 md:px-30"
      >
        <Job
          v-for="(item, index) in jobs"
          :person="item.person"
          :description="item.description"
          :level="item.level"
          :title="item.title"
          :link="item.link"
          :key="index"
        ></Job>
      </div>
    </section>
    <PerkSection></PerkSection>
    <GetInTouch></GetInTouch>
  </div>
</template>

<script>
import GetInTouch from "@/views/subComponents/GetInTouch.vue";
import PerkSection from "@/views/components/PerkSection";
import axios from "axios";
import { Job } from "@/components";

export default {
  components: {
    GetInTouch,
    PerkSection,
    Job
  },
  data() {
    return {
      jobs: {},
      loading: true,
      errored: false
    };
  },
  mounted() {
    axios
      .get("https://raw.githubusercontent.com/macdesket/zacky/main/jobs.json")
      .then(response => {
        this.jobs = response.data;
        this.loading = false;
      })
      .catch(error => {
        this.errored = error;
      });
  }
};
</script>

<style lang="css" scoped>
a {
  background-color: transparent;
}
img {
  border-style: none;
}
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button {
  -webkit-appearance: button;
}
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
*,
:after,
:before {
  box-sizing: inherit;
}
button {
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
img {
  border-style: solid;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  cursor: pointer;
}
.bg-white {
  background-color: #fff;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.h-16 {
  height: 4rem;
}
.m-0 {
  margin: 0;
}
.m-2 {
  margin: 0.5rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-auto {
  margin: auto;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.-mt-16 {
  margin-top: -4rem;
}
.overflow-auto {
  overflow: auto;
}
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pt-32 {
  padding-top: 8rem;
}
.text-center {
  text-align: center;
}
.text-primary {
  color: #ff6b6b;
}
.text-grey-smooth {
  color: #bfc4c8;
}
.text-secondary-dark {
  color: #021926;
}
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.w-64 {
  width: 16rem;
}
.w-full {
  width: 100%;
}
@media (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
@media (min-width: 992px) {
  .lg\:p-20 {
    padding: 5rem;
  }
}
@media (min-width: 1200px) {
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
body a:not(.navbar-link) {
  color: #ff6b6b;
}
body a:not(.navbar-link):active,
body a:not(.navbar-link):hover,
body a:not(.navbar-link):visited {
  color: #ff5064;
}
body a {
  text-decoration: none;
}
.btn {
  transition: all 0.2s;
  font-weight: 600;
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  border-radius: 9999px;
  padding: 0.75rem 2.5rem;
  line-height: 1.5;
  border-width: 1px;
}
.btn:focus {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
  outline: 0;
}
.main {
  background: #fff;
  min-height: 1440px;
}
.delimiter-line {
  border-width: 1px;
  border-color: #ff6b6b;
  width: 48px;
}
.career-offers {
  background-color: #fbfbfc;
  min-height: 400px;
}
.background-heading {
  font-size: 117px;
  color: hsla(0, 100%, 71%, 0.07);
}
.career-card {
  width: 40rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.career-card:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  border: 1px solid #ff6b6b;
}
.salary-value {
  color: #5e6d75;
}
.perk-icon {
  width: 3.75rem;
  min-width: 6rem;
}
.perks-info {
  color: #5e6d75;
}
.perk-component {
  width: 22rem;
}
.get-in-touch-button {
  box-shadow: 0 22px 24px 0 rgba(2, 25, 38, 0.09);
  transition: 0.5s;
}
.get-in-touch-button:hover {
  background-color: #ff6b6b;
  color: #fff;
  border-style: none;
}
.special-skills-section {
  background: #fbfbfb;
  height: 20rem;
}
/*! CSS Used from: Embedded */
div,
a,
span {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
/*! CSS Used fontfaces */
</style>
