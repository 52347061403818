<template>
  <section class="w-full">
    <div class="w-full flex justify-center">
      <div class="flex-col justify-center my-4">
        <div class="text-3xl text-secondary-dark font-medium my-2">
          Our Perks
        </div>
        <div class="rounded delimiter-line m-auto"></div>
      </div>
    </div>
    <div class="flex flex-row flex-wrap justify-around p-0 lg:p-20 our-perks">
      <div class="flex flex-row p-2 m-auto my-4 perk-component">
        <img
        width="46"
        height="46"

          src="img/careers/day.svg"
          class="m-2 mx-4 perk-icon filter-pink"
          alt=""
        />
        <div class="text-lg font-medium">
          1 Day
          <div class="text-xs font-normal perks-info">
            1 day off bonus for each year spent in company
          </div>
        </div>
      </div>
      <div class="flex flex-row p-2 m-auto my-4 perk-component">
        <img
        width="35"
        height="35"

          src="img/careers/health.svg"
          class="m-2 mx-4 perk-icon filter-pink"
          alt=""
        />
        <div class="text-lg font-medium">
          Healthcare
          <div class="text-xs font-normal perks-info">
            Private healthcare discounts.
          </div>
        </div>
      </div>
      <div class="flex flex-row p-2 m-auto my-4 perk-component">
        <img
        height="120"
        width="120"

          src="img/careers/time.svg"
          class="m-2 mx-4 perk-icon filter-pink"
          alt=""
        />
        <div class="text-lg font-medium">
          Flexible Hours
          <div class="text-xs font-normal perks-info">
            Adjust your plan to work efficiently.
          </div>
        </div>
      </div>
      <div class="flex flex-row p-2 m-auto my-4 perk-component">
        <img
        width="46"
        height="46"

          src="img/careers/remote.svg"
          class="m-2 mx-4 perk-icon filter-pink"
          alt=""
        />
        <div class="text-lg font-medium">
          Remote Work
          <div class="text-xs font-normal perks-info">
            Get things done - from home or an office.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.filter-pink{
    filter: invert(67%) sepia(29%) saturate(2736%) hue-rotate(312deg) brightness(94%) contrast(119%);
}

*,
:after,
:before {
  box-sizing: inherit;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
.rounded {
  border-radius: 0.25rem;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.justify-around {
  justify-content: space-around;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.m-2 {
  margin: 0.5rem;
}
.m-auto {
  margin: auto;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.text-secondary-dark {
  color: #021926;
}
.text-xs {
  font-size: 0.75rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.w-full {
  width: 100%;
}
@media (min-width: 992px) {
  .lg\:p-20 {
    padding: 5rem;
  }
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
.delimiter-line {
  border-width: 1px;
  border-color: #ff6b6b;
  width: 48px;
}
.perk-icon {
  width: 3.75rem;
  min-width: 6rem;
}
.perks-info {
  color: #5e6d75;
}
.perk-component {
  width: 22rem;
}
/*! CSS Used from: Embedded */
div {
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
</style>
